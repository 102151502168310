define("admin/templates/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eCWLRh6h",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"center-column px1 md:px0 pt2\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "admin/templates/utils.hbs"
    }
  });
  _exports.default = _default;
});