define("admin/templates/utils/graphs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ypsWlqym",
    "block": "{\"symbols\":[],\"statements\":[[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.graphs.active-devices\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    Daily Active Devices\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.graphs.joined-users\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    Joined Users\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.graphs.inserted-devices\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    Inserted Devices\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.graphs.snapshot-breakdown\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    Snapshot Breakdown\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.graphs.snapshot-timeline\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    Snapshot Timeline\\n\"]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "admin/templates/utils/graphs/index.hbs"
    }
  });
  _exports.default = _default;
});