define("admin/templates/utils/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rog7KEMP",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.download-recently-active\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    Download recent active users\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.graphs\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    Graphs\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.delete-user-data\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    Delete user data\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.update-light-site-content\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    Manually Update Light Site Content\\n\"]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"link-to\"]}",
    "meta": {
      "moduleName": "admin/templates/utils/index.hbs"
    }
  });
  _exports.default = _default;
});