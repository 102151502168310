define("admin/models/fota", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = Model.extend({
    version: attr('number'),
    enforced: attr('boolean'),
    device_type: attr('string'),
    available_to: attr('number'),
    build_type: attr('string'),
    type: 'fota',
    state: Ember.computed('available_to', function () {
      const availableTo = this.get('available_to');
      if (availableTo === 0) {
        return 'beta';
      }
      if (availableTo === 100) {
        return 'deployed';
      }
      return 'canary';
    }),
    user_build: Ember.computed('build_type', function () {
      return this.get('build_type') == 'USER';
    })
  });
  _exports.default = _default;
});