define("admin/templates/components/app-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aAGPNXCL",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"flex justify-between items-center\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"flex items-center\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"route\"],[\"search\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"img\",true],[12,\"class\",\"logo-width mr2\",null],[12,\"alt\",\"Light logo\",null],[12,\"src\",\"/assets/images/light-logo.svg\",null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"route\"],[\"utils.index\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"button\",true],[12,\"class\",\"button-reset body pointer\",null],[10],[1,1,0,0,\"Utilities\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"route\"],[\"apks\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"button\",true],[12,\"class\",\"button-reset body pointer\",null],[10],[1,1,0,0,\"Apks\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"route\"],[\"fotas\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"button\",true],[12,\"class\",\"button-reset body pointer\",null],[10],[1,1,0,0,\"Firmware\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"admin\",\"hasCurrentUser\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[12,\"class\",\"col-2 flex justify-end\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"button\",false],[23,\"class\",\"LogOut button-reset body pointer\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],[31,739,12,[27,[26,0,\"CallHead\"],[]],[\"logOut\"],null]],null],[10],[1,1,0,0,\"Log out\"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"route-action\",\"action\",\"link-to\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/components/app-header.hbs"
    }
  });
  _exports.default = _default;
});