define("admin/routes/fotas", ["exports", "admin/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    admin: Ember.inject.service(),
    notify: Ember.inject.service(),
    model() {
      return this.store.findAll('fota').then(fotas => fotas.sortBy('version').reverse());
    },
    actions: {
      triggerFotaSync() {
        const adminService = Ember.get(this, 'admin');
        if (!adminService) return;
        const notifyService = Ember.get(this, 'notify');
        return adminService.triggerFotaSync().then(() => {
          notifyService.alert('Successfully triggered fota sync.');
          this.refresh();
        }).catch(() => {
          return notifyService.alert('Something went wrong. Please try again.');
        });
      }
    }
  });
  _exports.default = _default;
});