define("admin/routes/users/show", ["exports", "admin/routes/authenticated", "admin/config/environment"], function (_exports, _authenticated, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    model({
      user_id
    }) {
      return this.store.findRecord('user', user_id);
    },
    actions: {
      signInAs() {
        const adminService = Ember.get(this, 'admin');
        const user = Ember.get(this, 'controller.model');
        if (!adminService || !user) return;
        const confirmed = confirm(`WARNING!\n\nYou will be logged in as ${user.email}.\n\nAny changes you make while logged in as ${user.email} are real and permanent.`);
        if (confirmed) {
          return adminService.signInAs(Ember.get(user, 'id')).then(() => {
            window.location = _environment.default.API.dashRedirectUrl + '?fromAdmin=true';
          });
        }
      }
    }
  });
  _exports.default = _default;
});