define("admin/routes/apks", ["exports", "admin/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    admin: Ember.inject.service(),
    notify: Ember.inject.service(),
    model() {
      return this.store.findAll('apk').then(apks => {
        const lightos = apks.filter(({
          name
        }) => name === 'com.lightos');
        const sideload = apks.filter(({
          name
        }) => name !== 'com.lightos');
        return Ember.RSVP.hash({
          lightos: lightos.sort(apk => apk.version).reverse(),
          sideload: sideload.sort(apk => apk.version).reverse()
        });
      });
    },
    actions: {
      triggerApkSync() {
        const adminService = Ember.get(this, 'admin');
        if (!adminService) return;
        const notifyService = Ember.get(this, 'notify');
        return adminService.triggerApkSync().then(() => {
          notifyService.alert('Successfully triggered apk sync.');
          this.refresh();
        }).catch(() => {
          return notifyService.alert('Something went wrong. Please try again.');
        });
      }
    }
  });
  _exports.default = _default;
});