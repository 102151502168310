define("admin/templates/components/search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aVUU55k+",
    "block": "{\"symbols\":[],\"statements\":[[9,\"form\",false],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],[31,16,12,[27,[26,0,\"CallHead\"],[]],[\"performSearch\"],null]],[[\"on\"],[\"submit\"]]],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"flex justify-center col-12 relative\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,119,5,[27,[26,3,\"CallHead\"],[]],null,[[\"aria-label\",\"class\",\"type\",\"value\"],[\"search\",\"input-reset input-radius body col-12 height-100 p1\",\"search\",[27,[26,2,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,267,5,[27,[26,3,\"CallHead\"],[]],null,[[\"class\",\"type\",\"value\"],[\"absolute t0 r0 b0 button-reset input-radius body px1 bg-color-white pointer\",\"submit\",\"Submit\"]]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"route-action\",\"action\",\"value\",\"input\"]}",
    "meta": {
      "moduleName": "admin/templates/components/search-bar.hbs"
    }
  });
  _exports.default = _default;
});